<script setup>
import MainBanner from "~/components/molecules/MainBanner.vue";
import MarketingBlock from "~/components/organisms/MarketingBlock.vue";
import HomeGrid from "~/components/organisms/HomeGrid.vue";
import Reviews from "~/components/organisms/Reviews.vue";
import BasicBlock from "~/components/molecules/BasicBlock.vue";
import CollectionsPreviewBasicBlock from "~/components/organisms/CollectionsPreviewBasicBlock.vue";
import SalesArguments from "~/components/organisms/SalesArguments.vue";
import ProductsCarousel from "~/components/organisms/ProductsCarousel.vue";
import Heading from "~/components/atoms/Heading.vue";
import Text from "~/components/atoms/Text.vue";
import Breadcrumbs from "~/components/organisms/Breadcrumbs.vue";
import CheckIcon from "~/components/icons/CheckIcon.vue";
import {defineAsyncComponent} from "vue";
import {useLastVisitedProducts} from "~/store/lastVisitedProducts.js";
import GreyBlock from "~/components/templates/GreyBlock.vue";
import {useCustomFetch} from '~/composables/customFetch.js';
import {useCurrencyStore} from "~/store/currencyStore.js";

const PIMDescription = defineAsyncComponent({
  loader: () => import(`~/components/organisms/PIMDescription.vue`),
  onError(error, retry, fail, attempts) {
    console.error(`Error loading PIMDescription ${attempts} спроб:`, error)
    fail()
  }
})

const currencyStore = useCurrencyStore()
const {t} = useI18n();

useHead({
  script: [
    {
      type: 'application/ld+json',
      children: {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": t('meta.domain'),
        "potentialAction": {
          "@type": "SearchAction",
          "target": t('meta.search'),
          "query-input": "required name=search_term_string"
        }
      }
    },
    {
      type: 'application/ld+json',
      children: {
        "@context": "http://www.schema.org",
        "@type": "LocalBusiness",
        "name": "Hillceramic",
        "url": t('meta.domain'),
        "logo": t('meta.logo'),
        "image": t('meta.logo'),
        "description": t('meta.localBusiness.description'),
        "email": "order@hillceramic.se ",
        "hasMap": "https://goo.gl/maps/32STmkyLsmehfKDD7",
        "openingHours": ["Mo-Th 08:00-16:00", "Fr 08:00-14:00"],
        "telephone": "0340549797",
        "priceRange": currencyStore.currency,
        "sameAs": ["https://www.instagram.com/hillceramic/", "https://www.youtube.com/channel/UCrRjvxI9oLEXNDn4FK9osJQ", "https://www.facebook.com/hillceramic/", "https://www.pinterest.se/hillceramic/"],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Fastarpsv\u00e4gen 14",
          "addressLocality": "Tv\u00e5\u00e5ker",
          "postalCode": "432 78",
          "addressCountry": "Sweden"
        },
        "geo": {"@type": "GeoCoordinates", "longitude": "57.043524", "latitude": "12.388961"}
      }

    },
  ],
  title: t('home.meta.title'),
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: t('home.meta.description')
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: t('home.meta.description')
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: t('home.meta.title')
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: t('meta.domain')
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: t('meta.logo')
    },
  ]
})

const lastVisitedProductsStore = useLastVisitedProducts()

const props = defineProps({
  bottomDescription: {
    type: Object,
  },
  city: {
    type: String,
  },
  breadcrumbs: {
    type: Array,
  }
});

const grids = [
  {
    title: t('home.grids.0.title'),
    menu_items: [
      {title: t('home.grids.0.menu.0.title'), link: t('home.grids.0.menu.0.url')},
      {title: t('home.grids.0.menu.1.title'), link: t('home.grids.0.menu.1.url')},
      {title: t('home.grids.0.menu.2.title'), link: t('home.grids.0.menu.2.url')},
      {title: t('home.grids.0.menu.3.title'), link: t('home.grids.0.menu.3.url')},
      {title: t('home.grids.0.menu.4.title'), link: t('home.grids.0.menu.4.url')},
      {title: t('home.grids.0.menu.5.title'), link: t('home.grids.0.menu.5.url')},
      {title: t('home.grids.0.menu.6.title'), link: t('home.grids.0.menu.6.url')},
      {title: t('home.grids.0.menu.7.title'), link: t('home.grids.0.menu.7.url')},
      {title: t('home.grids.0.menu.8.title'), link: t('home.grids.0.menu.8.url'), underline: true},
    ],
    items: [
      {
        title: t('home.grids.0.items.0.title'),
        url: t('home.grids.0.items.0.url'),
        image: '/images/banners/home/klinker/Klinker.webp',
        mobile_image: '/images/banners/home/klinker/Klinker-200px.webp'
      },
      {
        title: t('home.grids.0.items.1.title'),
        url: t('home.grids.0.items.1.url'),
        image: '/images/banners/home/klinker/Utomhus.webp',
        mobile_image: '/images/banners/home/klinker/Utomhus-200px.webp'
      },
      {
        title: t('home.grids.0.items.2.title'),
        url: t('home.grids.0.items.2.url'),
        image: '/images/banners/home/klinker/Kakel.webp',
        mobile_image: '/images/banners/home/klinker/Kakel-200px.webp'
      },
      {
        title: t('home.grids.0.items.3.title'),
        url: t('home.grids.0.items.3.url'),
        image: '/images/banners/home/klinker/Traklinker.webp',
        mobile_image: '/images/banners/home/klinker/Traklinker-200px.webp'
      },
      {
        title: t('home.grids.0.items.4.title'),
        url: t('home.grids.0.items.4.url'),
        image: '/images/banners/home/klinker/Terrazzo.webp',
        mobile_image: '/images/banners/home/klinker/Terrazzo-200px.webp'
      },
      {
        title: t('home.grids.0.items.5.title'),
        url: t('home.grids.0.items.5.url'),
        image: '/images/banners/home/klinker/Kalksten.webp',
        mobile_image: '/images/banners/home/klinker/Kalksten-200px.webp'
      },
      {
        title: t('home.grids.0.items.6.title'),
        url: t('home.grids.0.items.6.url'),
        image: '/images/banners/home/klinker/Marmor.webp',
        mobile_image: '/images/banners/home/klinker/Marmor-200px.webp'
      },
      {
        title: t('home.grids.0.items.7.title'),
        url: t('home.grids.0.items.7.url'),
        image: '/images/banners/home/klinker/Marockanskt.webp',
        mobile_image: '/images/banners/home/klinker/Marockanskt-200px.webp'
      },
    ],
    lazy: false
  },
  {
    title: t('home.grids.1.title'),
    menu_items: [
      {title: t('home.grids.1.menu.0.title'), link: t('home.grids.1.menu.0.url')},
      {title: t('home.grids.1.menu.1.title'), link: t('home.grids.1.menu.1.url')},
      {title: t('home.grids.1.menu.2.title'), link: t('home.grids.1.menu.2.url')},
      {title: t('home.grids.1.menu.3.title'), link: t('home.grids.1.menu.3.url')},
      {title: t('home.grids.1.menu.4.title'), link: t('home.grids.1.menu.4.url')},
      {title: t('home.grids.1.menu.5.title'), link: t('home.grids.1.menu.5.url')},
      {title: t('home.grids.1.menu.6.title'), link: t('home.grids.1.menu.6.url')},
      {title: t('home.grids.1.menu.7.title'), link: t('home.grids.1.menu.7.url')},
      {title: t('home.grids.1.menu.8.title'), link: t('home.grids.1.menu.8.url')},
      {title: t('home.grids.1.menu.9.title'), link: t('home.grids.1.menu.9.url')},
      {title: t('home.grids.1.menu.10.title'), link: t('home.grids.1.menu.10.url'), underline: true},
    ],
    items: [
      {
        title: t('home.grids.1.items.0.title'),
        url: t('home.grids.1.items.0.url'),
        image: '/images/banners/home/badrum/Badrum.webp',
        mobile_image: '/images/banners/home/badrum/Badrum-200px.webp'
      },
      {
        title: t('home.grids.1.items.1.title'),
        url: t('home.grids.1.items.1.url'),
        image: '/images/banners/home/badrum/Badrumsmobler.webp',
        mobile_image: '/images/banners/home/badrum/Badrumsmobler-200px.webp'
      },
      {
        title: t('home.grids.1.items.2.title'),
        url: t('home.grids.1.items.2.url'),
        image: '/images/banners/home/badrum/Dusch.webp',
        mobile_image: '/images/banners/home/badrum/Dusch-200px.webp'
      },
      {
        title: t('home.grids.1.items.3.title'),
        url: t('home.grids.1.items.3.url'),
        image: '/images/banners/home/badrum/Tvattstall.webp',
        mobile_image: '/images/banners/home/badrum/Tvattstall-200px.webp'
      },
      {
        title: t('home.grids.1.items.4.title'),
        url: t('home.grids.1.items.4.url'),
        image: '/images/banners/home/badrum/Toalett.webp',
        mobile_image: '/images/banners/home/badrum/Toalett-200px.webp'
      },
      {
        title: t('home.grids.1.items.5.title'),
        url: t('home.grids.1.items.5.url'),
        image: '/images/banners/home/badrum/Blandare.webp',
        mobile_image: '/images/banners/home/badrum/Blandare-200px.webp'
      },
      {
        title: t('home.grids.1.items.6.title'),
        url: t('home.grids.1.items.6.url'),
        image: '/images/banners/home/badrum/Badkar.webp',
        mobile_image: '/images/banners/home/badrum/Badkar-200px.webp'
      },
      {
        title: t('home.grids.1.items.7.title'),
        url: t('home.grids.1.items.7.url'),
        image: '/images/banners/home/badrum/Badrumsaccessoarer.webp',
        mobile_image: '/images/banners/home/badrum/Badrumsaccessoarer-200px.webp'
      },
    ],
    lazy: true
  },
  {
    title: t('home.grids.2.title'),
    menu_items: [
      {title: t('home.grids.2.menu.0.title'), link: t('home.grids.2.menu.0.url')},
      {title: t('home.grids.2.menu.1.title'), link: t('home.grids.2.menu.1.url')},
      {title: t('home.grids.2.menu.2.title'), link: t('home.grids.2.menu.2.url')},
      {title: t('home.grids.2.menu.3.title'), link: t('home.grids.2.menu.3.url')},
      {title: t('home.grids.2.menu.4.title'), link: t('home.grids.2.menu.4.url')},
      {title: t('home.grids.2.menu.5.title'), link: t('home.grids.2.menu.5.url')},
      {title: t('home.grids.2.menu.6.title'), link: t('home.grids.2.menu.6.url')},
      {title: t('home.grids.2.menu.7.title'), link: t('home.grids.2.menu.7.url'), underline: true},
    ],
    items: [
      {
        title: t('home.grids.2.items.0.title'),
        url: t('home.grids.2.items.0.url'),
        image: '/images/banners/home/utomhus/Tradgard-Utomhus.webp',
        mobile_image: '/images/banners/home/utomhus/Tradgard-Utomhus-200px.webp'
      },
      {
        title: t('home.grids.2.items.1.title'),
        url: t('home.grids.2.items.1.url'),
        image: '/images/banners/home/utomhus/Eldstad-Eldskal.webp',
        mobile_image: '/images/banners/home/utomhus/Eldstad-Eldskal-200px.webp'
      },
      {
        title: t('home.grids.2.items.2.title'),
        url: t('home.grids.2.items.2.url'),
        image: '/images/banners/home/utomhus/Pizzaugn.webp',
        mobile_image: '/images/banners/home/utomhus/Pizzaugn-200px.webp'
      },
      {
        title: t('home.grids.2.items.3.title'),
        url: t('home.grids.2.items.3.url'),
        image: '/images/banners/home/utomhus/Eldstad.webp',
        mobile_image: '/images/banners/home/utomhus/Eldstad-200px.webp'
      },
      {
        title: t('home.grids.2.items.4.title'),
        url: t('home.grids.2.items.4.url'),
        image: '/images/banners/home/utomhus/Eldskal.webp',
        mobile_image: '/images/banners/home/utomhus/Eldskal-200px.webp'
      },
      {
        title: t('home.grids.2.items.5.title'),
        url: t('home.grids.2.items.5.url'),
        image: '/images/banners/home/utomhus/Grilgaller.webp',
        mobile_image: '/images/banners/home/utomhus/Grilgaller-200px.webp'
      },
      {
        title: t('home.grids.2.items.6.title'),
        url: t('home.grids.2.items.6.url'),
        image: '/images/banners/home/utomhus/Kamin.webp',
        mobile_image: '/images/banners/home/utomhus/Kamin-200px.webp'
      },
      {
        title: t('home.grids.2.items.7.title'),
        url: t('home.grids.2.items.7.url'),
        image: '/images/banners/home/utomhus/Etanolkamin.webp',
        mobile_image: '/images/banners/home/utomhus/Etanolkamin-200px.webp'
      },
    ],
    lazy: true
  },
  {
    title: t('home.grids.3.title'),
    menu_items: [
      {title: t('home.grids.3.menu.0.title'), link: t('home.grids.3.menu.0.url')},
      {title: t('home.grids.3.menu.1.title'), link: t('home.grids.3.menu.1.url')},
      {title: t('home.grids.3.menu.2.title'), link: t('home.grids.3.menu.2.url')},
      {title: t('home.grids.3.menu.3.title'), link: t('home.grids.3.menu.3.url')},
      {title: t('home.grids.3.menu.4.title'), link: t('home.grids.3.menu.4.url')},
      {title: t('home.grids.3.menu.5.title'), link: t('home.grids.3.menu.5.url')},
      {title: t('home.grids.3.menu.6.title'), link: t('home.grids.3.menu.6.url'), underline: true},
    ],
    items: [
      {
        title: t('home.grids.3.items.0.title'),
        url: t('home.grids.3.items.0.url'),
        image: '/images/banners/home/inredning/villeroy-boch-485.webp',
        mobile_image: '/images/banners/home/inredning/villeroy-boch-200.webp'
      },
      {
        title: t('home.grids.3.items.1.title'),
        url: t('home.grids.3.items.1.url'),
        image: '/images/banners/home/inredning/karlsson-485.webp',
        mobile_image: '/images/banners/home/inredning/karlsson-200.webp'
      },
      {
        title: t('home.grids.3.items.2.title'),
        url: t('home.grids.3.items.2.url'),
        image: '/images/banners/home/inredning/humble-485.webp',
        mobile_image: '/images/banners/home/inredning/humble-200.webp'
      },
      {
        title: t('home.grids.3.items.3.title'),
        url: t('home.grids.3.items.3.url'),
        image: '/images/banners/home/inredning/bord-485.webp',
        mobile_image: '/images/banners/home/inredning/bord-200.webp'
      },
      {
        title: t('home.grids.3.items.4.title'),
        url: t('home.grids.3.items.4.url'),
        image: '/images/banners/home/inredning/hangare-485.webp',
        mobile_image: '/images/banners/home/inredning/hangare-200.webp'
      },
      {
        title: t('home.grids.3.items.5.title'),
        url: t('home.grids.3.items.5.url'),
        image: '/images/banners/home/inredning/belysning-485.webp',
        mobile_image: '/images/banners/home/inredning/belysning-200.webp'
      },
      {
        title: t('home.grids.3.items.6.title'),
        url: t('home.grids.3.items.6.url'),
        image: '/images/banners/home/inredning/hylla-485.webp',
        mobile_image: '/images/banners/home/inredning/hylla-200.webp'
      },
      {
        title: t('home.grids.3.items.7.title'),
        url: t('home.grids.3.items.7.url'),
        image: '/images/banners/home/inredning/klockor-485.webp',
        mobile_image: '/images/banners/home/inredning/klockor-200.webp'
      },
    ],
    lazy: true
  },
  {
    title: t('home.grids.4.title'),
    menu_items: [
      {title: t('home.grids.4.menu.0.title'), link: t('home.grids.4.menu.0.url')},
      {title: t('home.grids.4.menu.1.title'), link: t('home.grids.4.menu.1.url')},
      {title: t('home.grids.4.menu.2.title'), link: t('home.grids.4.menu.2.url')},
      {title: t('home.grids.4.menu.3.title'), link: t('home.grids.4.menu.3.url')},
      {title: t('home.grids.4.menu.4.title'), link: t('home.grids.4.menu.4.url')},
      {title: t('home.grids.4.menu.5.title'), link: t('home.grids.4.menu.5.url')},
      {title: t('home.grids.4.menu.6.title'), link: t('home.grids.4.menu.6.url')},
      {title: t('home.grids.4.menu.7.title'), link: t('home.grids.4.menu.7.url')},
      {title: t('home.grids.4.menu.8.title'), link: t('home.grids.4.menu.8.url')},
      {title: t('home.grids.4.menu.9.title'), link: t('home.grids.4.menu.9.url')},
      {title: t('home.grids.4.menu.10.title'), link: t('home.grids.4.menu.10.url'), underline: true},
    ],
    items: [
      {
        title: t('home.grids.4.items.0.title'),
        url: t('home.grids.4.items.0.url'),
        image: '/images/banners/home/verktyg/Kakelskarare.webp',
        mobile_image: '/images/banners/home/verktyg/Kakelskarare-200px.webp'
      },
      {
        title: t('home.grids.4.items.1.title'),
        url: t('home.grids.4.items.1.url'),
        image: '/images/banners/home/verktyg/Fix&Fog.webp',
        mobile_image: '/images/banners/home/verktyg/Fix&Fog-200px.webp'
      },
      {
        title: t('home.grids.4.items.2.title'),
        url: t('home.grids.4.items.2.url'),
        image: '/images/banners/home/verktyg/Nivasystem.webp',
        mobile_image: '/images/banners/home/verktyg/Nivasystem-200px.webp'
      },
      {
        title: t('home.grids.4.items.3.title'),
        url: t('home.grids.4.items.3.url'),
        image: '/images/banners/home/verktyg/Pedestal.webp',
        mobile_image: '/images/banners/home/verktyg/Pedestal-200px.webp'
      },
      {
        title: t('home.grids.4.items.4.title'),
        url: t('home.grids.4.items.4.url'),
        image: '/images/banners/home/verktyg/Kakelkryss.webp',
        mobile_image: '/images/banners/home/verktyg/Kakelkryss-200px.webp'
      },
    ],
    lazy: true
  },
]
const extra_grid = {
  title: t('home.grids.5.title'),
  hideMenuOnMobile: true,
  menu_items: [
    {title: t('home.grids.5.menu.0.title'), link: t('home.grids.5.menu.0.url')},
    {title: t('home.grids.5.menu.1.title'), link: t('home.grids.5.menu.1.url')},
    {title: t('home.grids.5.menu.2.title'), link: t('home.grids.5.menu.2.url')},
    {title: t('home.grids.5.menu.3.title'), link: t('home.grids.5.menu.3.url')},
    {title: t('home.grids.5.menu.4.title'), link: t('home.grids.5.menu.4.url')},
    {title: t('home.grids.5.menu.5.title'), link: t('home.grids.5.menu.5.url')},
    {title: t('home.grids.5.menu.6.title'), link: t('home.grids.5.menu.6.url')},
    {title: t('home.grids.5.menu.7.title'), link: t('home.grids.5.menu.7.url')},
    {title: t('home.grids.5.menu.8.title'), link: t('home.grids.5.menu.8.url')},
    {title: t('home.grids.5.menu.9.title'), link: t('home.grids.5.menu.9.url'), underline: true},
  ],
  items: [
    {
      title: t('home.grids.5.items.0.title'),
      url: t('home.grids.5.items.0.url'),
      image: '/images/banners/home/contact/Showroom i Malmo.webp',
      mobile_image: '/images/banners/home/contact/Showroom i Malmo-200px.webp'
    },
    {
      title: t('home.grids.5.items.1.title'),
      url: t('home.grids.5.items.1.url'),
      image: '/images/banners/home/contact/kontakta-oss-desktop.jpg',
      mobile_image: '/images/banners/home/contact/kontakta-oss-mobile.jpg'
    },
    {
      title: t('home.grids.5.items.2.title'),
      url: t('home.grids.5.items.2.url'),
      image: '/images/banners/home/contact/Foretagsoffert.webp',
      mobile_image: '/images/banners/home/contact/Foretagsoffert-200px.webp'
    }
  ],
  lazy: true
}
const collections_menu_items = [
  {title: 'Stonehenge', link: '/stonehenge/'},
  {title: 'Kalksten Nord', link: '/kalksten-nord/'},
  {title: 'Guss-Terrazzo', link: '/guss-terrazzo/'},
  {title: 'Kalksten Öland', link: '/kalksten-oland/'},
  {title: 'Terrazzo Italia', link: '/terrazzo-italia/'},
  {title: 'Earth', link: '/earth/'},
  {title: 'Oxford', link: '/oxford/'},
  {title: 'Ekeby', link: '/ekeby/'},
  {title: 'Soapstone Premium', link: '/soapstone-premium/'},
  {
    title: 'Se alla kollektioner',
    link: '/kollektioner/',
    underline: true
  }]

const {data} = await useCustomFetch('home', {
  query: {
    'lastVisitedProducts[]': lastVisitedProductsStore.products?.map(product => product.id) ?? []
  }
})

const {reviews, collections, viewed_products, banners, campaign_banner, counter} = data?.value

</script>

<template>
  <div class="big-page-wrapper">
    <div class="page-wrapper">
      <MainBanner v-if="campaign_banner && !props.city"
                  :url="campaign_banner.url"
                  :mobile-src="campaign_banner.mobile_image"
                  :src="campaign_banner.desktop_image"/>

      <div v-if="props.city" class="container g-32">
        <Breadcrumbs :breadcrumbs="props.breadcrumbs"/>

        <div class="g-8">
          <Heading>Kakel och klinker med leverans till {{ props.city }}</Heading>
          <Heading style="font-size: 14px; font-weight: 400;" tag="h2">Vi är Malmös största online butik för kakel och
            klinker
          </Heading>
        </div>

        <div class="row full-width flex-start g-32 stretch column-mobile">
          <div class="city-block g-24 no-flex">
            <Heading align="center" tag="h3">{{ props.city }}</Heading>

            <div class="g-16">
              <Text>Vill du inte trängas i butiker eller i utställningar?</Text>
              <Text>Gör som många andra kunder från Malmö, beställ kakelprover online hos Hill Ceramic®</Text>
              <Text>Dessa skickas hem till dig, proverna behöver inte returneras utan ni behåller dom.</Text>
              <Text> Vi erbjuder ingen fysisk utställning i Malmö.</Text>
            </div>

            <div>
              <div class="row full-width space-between">
                <Text tag="b">Leverans till Malmö: 2-3 dagar</Text>
                <CheckIcon width="14px" height="14px" stroke="black"/>
              </div>
              <div class="row full-width space-between">
                <Text tag="b">Fraktkostnad 395 kr</Text>
                <CheckIcon width="14px" height="14px" stroke="black"/>
              </div>
              <div class="row full-width space-between">
                <Text tag="b">Beställ provplatta</Text>
                <CheckIcon width="14px" height="14px" stroke="black"/>
              </div>
              <div class="row full-width space-between">
                <Text tag="b">Få först - Betala sen med Klarna</Text>
                <CheckIcon width="14px" height="14px" stroke="black"/>
              </div>
            </div>
          </div>
          <div>
            <img style="max-width: 100%;" src="/images/city/city.jpeg"/>
          </div>
        </div>
      </div>
      <GreyBlock class="grey-bg g-72">

        <div class="container">
          <div class="page-wrapper">
            <MarketingBlock :banner-slots="[0,1]" v-if="counter || !$device.isMobile" :counter="counter"
                            :banners="$device.isMobile ? [] : banners"/>

            <div class="g-72">
              <HomeGrid v-for="grid in grids" :lazy="grid.lazy" :title="grid.title" :menu_items="grid.menu_items"
                        :items="grid.items"/>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="page-wrapper">
            <div class="g-72">
              <Reviews :data="reviews"/>

              <HomeGrid :title="extra_grid.title" :hide-menu-on-mobile="extra_grid.hideMenuOnMobile"
                        :menu_items="extra_grid.menu_items"
                        :items="extra_grid.items"/>
              <CollectionsPreviewBasicBlock :collections="collections"
                                            :title="$t('collection.most_popular')"
                                            :menu_items="collections_menu_items"/>

              <BasicBlock v-if="viewed_products.length">
                <template #title>{{ $t("product.last_viewed") }}</template>
                <template #default>
                  <div class="carousel-container">
                    <ProductsCarousel :products="viewed_products"/>
                  </div>
                </template>
              </BasicBlock>
            </div>
          </div>
        </div>
      </GreyBlock>
    </div>

    <div class="container" v-if="props.city && props.bottomDescription">
      <PIMDescription :styles="props.bottomDescription.styles" :html="props.bottomDescription.html"/>
    </div>

    <SalesArguments/>

  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";

.grey-bg {
  background-position-y: 450px;

  @include smd {
    background-color: white;
  }
}

.city-block {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  border-radius: 5px;
  background-color: #fff;
  padding: 40px 50px;

  h3 {
    font-size: 24px;
  }
}
</style>
